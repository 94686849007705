.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 120px);
}

.container {
  position: relative;
  border-radius: var(--button-border-radius);
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03),
    0px 30px 24px rgba(119, 119, 119, 0.04),
    0px 12px 10px rgba(119, 119, 119, 0.03),
    0px 4px 3px rgba(119, 119, 119, 0.02);
  padding: 12px;
  margin-bottom: 80px;
}

.return_btn {
  position: absolute;
  top: 12px;
  left: -70px;
}

.img_box {
  position: relative;
  width: 360px;
  height: 280px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.img {
  margin-bottom: 20px;
}

.current_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove_btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
}

.submit_btn {
  width: 100%;
}

.return_btn_mobile {
  display: none;
}

.file_input {
  display: none;
}

@media screen and (max-width: 600px) {
  .return_btn_mobile {
    display: block;
    width: 100%;
    max-width: 292px;
    margin-top: 12px;
    margin-bottom: 18px;
  }

  .return_btn {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .content {
    min-height: calc(100vh - 114px);
  }
}

@media screen and (max-width: 430px) {
  .content {
    min-height: calc(100vh - 110px);
  }
}

@media screen and (max-width: 412px) {
  .img_box {
    width: 268px;
  }
}
