.card {
  max-width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 25%;
  margin-top: 5%;
  max-width: 40%;
  height: auto;
  padding: 35px;

  border: 1px solid rgba(255, 255, 255, .25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(15px);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.single-card {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.single-card__buttons {
  display: flex;
  margin-bottom: 40px;
}

.single-card__link {
  color: #000;
}

.single-card__button {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.single-card__button svg {
  margin-right: 5px;
}

.single-card__button:last-child {
  margin-right: 0px;
}

.single-card__edit {
  /* margin-left: 40px; */
  /* color: #fcb900; */
  /* text-decoration: underline; */
  background-color: aliceblue;
  
}

.single-card__text, .single-card__text_with_link {
  display: flex;
  align-items: center;
}

.single-card__text svg {
  margin-right: 12px;
}

.single-card__image {
  object-fit: cover;
  align-self: flex-start;
  height: 480px;
  width: 480px;
  border-radius: 15px;
  max-width: 100%;
}

.single-card__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 60px;
}


.single-card__header-info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 18px;
}

.single-card__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;

  line-height: 49px;
  color: #000;
  margin: 0;
}

@media screen and (max-width: 820px) {
  .single-card__text_with_link {
    display: block;
  }

  /* .single-card__edit { */
    /* margin-left: 20px; */
    /* margin-top: 20px; */
    /* display: inline-block; */
  /* } */

  .single-card {
    display: block;
  }

  .single-card__info {
    padding-left: 0px;
  }

  .single-card__image {
    margin-bottom: 40px;
  }

  .single-card__buttons {
    display: block;
  }

  .single-card__button {
    margin-bottom: 16px;
  }
}

