/* padding */
.p-1 {
    padding: var(--offset-base-size);
}
.p-2 {
    padding: calc(var(--offset-base-size)  * 2);
}
.p-3 {
    padding: calc(var(--offset-base-size)  * 3);
}
.p-4 {
    padding: calc(var(--offset-base-size)  * 4);
}
.p-5 {
    padding: calc(var(--offset-base-size)  * 5);
}
.p-6 {
    padding: calc(var(--offset-base-size)  * 6);
}
.p-7 {
    padding: calc(var(--offset-base-size)  * 7);
}
.p-8 {
    padding: calc(var(--offset-base-size)  * 8);
}
.p-9 {
    padding: calc(var(--offset-base-size)  * 9);
}
.p-10 {
    padding: calc(var(--offset-base-size)  * 10);
}
.p-12 {
    padding: calc(var(--offset-base-size)  * 12);
}
.p-15 {
    padding: calc(var(--offset-base-size)  * 15);
}
.p-16 {
    padding: calc(var(--offset-base-size)  * 16);
}
.p-20 {
    padding: calc(var(--offset-base-size)  * 20);
}
.p-25 {
    padding: calc(var(--offset-base-size)  * 25);
}
.p-30 {
    padding: calc(var(--offset-base-size)  * 30);
}
.p-35 {
    padding: calc(var(--offset-base-size)  * 35);
}
.p-40 {
    padding: calc(var(--offset-base-size)  * 40);
}

.pl-1 {
    padding-left: var(--offset-base-size);
}
.pl-2 {
    padding-left: calc(var(--offset-base-size)  * 2);
}
.pl-3 {
    padding-left: calc(var(--offset-base-size)  * 3);
}
.pl-4 {
    padding-left: calc(var(--offset-base-size)  * 4);
}
.pl-5 {
    padding-left: calc(var(--offset-base-size)  * 5);
}
.pl-6 {
    padding-left: calc(var(--offset-base-size)  * 6);
}
.pl-7 {
    padding-left: calc(var(--offset-base-size)  * 7);
}
.pl-8 {
    padding-left: calc(var(--offset-base-size)  * 8);
}
.pl-9 {
    padding-left: calc(var(--offset-base-size)  * 9);
}
.pl-10 {
    padding-left: calc(var(--offset-base-size)  * 10);
}
.pl-12 {
    padding-left: calc(var(--offset-base-size)  * 12);
}
.pl-15 {
    padding-left: calc(var(--offset-base-size)  * 15);
}
.pl-16 {
    padding-left: calc(var(--offset-base-size)  * 16);
}
.pl-20 {
    padding-left: calc(var(--offset-base-size)  * 20);
}
.pl-25 {
    padding-left: calc(var(--offset-base-size)  * 25);
}
.pl-30 {
    padding-left: calc(var(--offset-base-size)  * 30);
}
.pl-35 {
    padding-left: calc(var(--offset-base-size)  * 35);
}
.pl-40 {
    padding-left: calc(var(--offset-base-size)  * 40);
}

.pr-1 {
    padding-right: var(--offset-base-size);
}
.pr-2 {
    padding-right: calc(var(--offset-base-size)  * 2);
}
.pr-3 {
    padding-right: calc(var(--offset-base-size)  * 3);
}
.pr-4 {
    padding-right: calc(var(--offset-base-size)  * 4);
}
.pr-5 {
    padding-right: calc(var(--offset-base-size)  * 5);
}
.pr-6 {
    padding-right: calc(var(--offset-base-size)  * 6);
}
.pr-7 {
    padding-right: calc(var(--offset-base-size)  * 7);
}
.pr-8 {
    padding-right: calc(var(--offset-base-size)  * 8);
}
.pr-9 {
    padding-right: calc(var(--offset-base-size)  * 9);
}
.pr-10 {
    padding-right: calc(var(--offset-base-size)  * 10);
}
.pr-12 {
    padding-right: calc(var(--offset-base-size)  * 12);
}
.pr-15 {
    padding-right: calc(var(--offset-base-size)  * 15);
}
.pr-16 {
    padding-right: calc(var(--offset-base-size)  * 16);
}
.pr-20 {
    padding-right: calc(var(--offset-base-size)  * 20);
}
.pr-25 {
    padding-right: calc(var(--offset-base-size)  * 25);
}
.pr-30 {
    padding-right: calc(var(--offset-base-size)  * 30);
}
.pr-35 {
    padding-right: calc(var(--offset-base-size)  * 35);
}
.pr-40 {
    padding-right: calc(var(--offset-base-size)  * 40);
}

.pt-1 {
    padding-top: var(--offset-base-size);
}
.pt-2 {
    padding-top: calc(var(--offset-base-size)  * 2);
}
.pt-3 {
    padding-top: calc(var(--offset-base-size)  * 3);
}
.pt-4 {
    padding-top: calc(var(--offset-base-size)  * 4);
}
.pt-5 {
    padding-top: calc(var(--offset-base-size)  * 5);
}
.pt-6 {
    padding-top: calc(var(--offset-base-size)  * 6);
}
.pt-7 {
    padding-top: calc(var(--offset-base-size)  * 7);
}
.pt-8 {
    padding-top: calc(var(--offset-base-size)  * 8);
}
.pt-9 {
    padding-top: calc(var(--offset-base-size)  * 9);
}
.pt-10 {
    padding-top: calc(var(--offset-base-size)  * 10);
}
.pt-12 {
    padding-top: calc(var(--offset-base-size)  * 12);
}
.pt-15 {
    padding-top: calc(var(--offset-base-size)  * 15);
}
.pt-16 {
    padding-top: calc(var(--offset-base-size)  * 16);
}
.pt-20 {
    padding-top: calc(var(--offset-base-size)  * 20);
}
.pt-25 {
    padding-top: calc(var(--offset-base-size)  * 25);
}
.pt-30 {
    padding-top: calc(var(--offset-base-size)  * 30);
}
.pt-35 {
    padding-top: calc(var(--offset-base-size)  * 35);
}
.pt-40 {
    padding-top: calc(var(--offset-base-size)  * 40);
}

.pb-1 {
    padding-bottom: var(--offset-base-size);
}
.pb-2 {
    padding-bottom: calc(var(--offset-base-size)  * 2);
}
.pb-3 {
    padding-bottom: calc(var(--offset-base-size)  * 3);
}
.pb-4 {
    padding-bottom: calc(var(--offset-base-size)  * 4);
}
.pb-5 {
    padding-bottom: calc(var(--offset-base-size)  * 5);
}
.pb-6 {
    padding-bottom: calc(var(--offset-base-size)  * 6);
}
.pb-7 {
    padding-bottom: calc(var(--offset-base-size)  * 7);
}
.pb-8 {
    padding-bottom: calc(var(--offset-base-size)  * 8);
}
.pb-9 {
    padding-bottom: calc(var(--offset-base-size)  * 9);
}
.pb-10 {
    padding-bottom: calc(var(--offset-base-size)  * 10);
}
.pb-12 {
    padding-bottom: calc(var(--offset-base-size)  * 12);
}
.pb-15 {
    padding-bottom: calc(var(--offset-base-size)  * 15);
}
.pb-16 {
    padding-bottom: calc(var(--offset-base-size)  * 16);
}
.pb-20 {
    padding-bottom: calc(var(--offset-base-size)  * 20);
}
.pb-25 {
    padding-bottom: calc(var(--offset-base-size)  * 25);
}
.pb-30 {
    padding-bottom: calc(var(--offset-base-size)  * 30);
}
.pb-35 {
    padding-bottom: calc(var(--offset-base-size)  * 35);
}
.pb-40 {
    padding-bottom: calc(var(--offset-base-size)  * 40);
}

/* margin */

.m-1 {
    margin: var(--offset-base-size);
}
.m-2 {
    margin: calc(var(--offset-base-size)  * 2);
}
.m-3 {
    margin: calc(var(--offset-base-size)  * 3);
}
.m-4 {
    margin: calc(var(--offset-base-size)  * 4);
}
.m-5 {
    margin: calc(var(--offset-base-size)  * 5);
}
.m-6 {
    margin: calc(var(--offset-base-size)  * 6);
}
.m-7 {
    margin: calc(var(--offset-base-size)  * 7);
}
.m-8 {
    margin: calc(var(--offset-base-size)  * 8);
}
.m-9 {
    margin: calc(var(--offset-base-size)  * 9);
}
.m-10 {
    margin: calc(var(--offset-base-size)  * 10);
}
.m-12 {
    margin: calc(var(--offset-base-size)  * 12);
}
.m-15 {
    margin: calc(var(--offset-base-size)  * 15);
}
.m-16 {
    margin: calc(var(--offset-base-size)  * 16);
}
.m-20 {
    margin: calc(var(--offset-base-size)  * 20);
}
.m-25 {
    margin: calc(var(--offset-base-size)  * 25);
}
.m-30 {
    margin: calc(var(--offset-base-size)  * 30);
}
.m-35 {
    margin: calc(var(--offset-base-size)  * 35);
}
.m-40 {
    margin: calc(var(--offset-base-size)  * 40);
}

.ml-1 {
    margin-left: var(--offset-base-size);
}
.ml-2 {
    margin-left: calc(var(--offset-base-size)  * 2);
}
.ml-3 {
    margin-left: calc(var(--offset-base-size)  * 3);
}
.ml-4 {
    margin-left: calc(var(--offset-base-size)  * 4);
}
.ml-5 {
    margin-left: calc(var(--offset-base-size)  * 5);
}
.ml-6 {
    margin-left: calc(var(--offset-base-size)  * 6);
}
.ml-7 {
    margin-left: calc(var(--offset-base-size)  * 7);
}
.ml-8 {
    margin-left: calc(var(--offset-base-size)  * 8);
}
.ml-9 {
    margin-left: calc(var(--offset-base-size)  * 9);
}
.ml-10 {
    margin-left: calc(var(--offset-base-size)  * 10);
}
.ml-12 {
    margin-left: calc(var(--offset-base-size)  * 12);
}
.ml-15 {
    margin-left: calc(var(--offset-base-size)  * 15);
}
.ml-16 {
    margin-left: calc(var(--offset-base-size)  * 16);
}
.ml-20 {
    margin-left: calc(var(--offset-base-size)  * 20);
}
.ml-25 {
    margin-left: calc(var(--offset-base-size)  * 25);
}
.ml-30 {
    margin-left: calc(var(--offset-base-size)  * 30);
}
.ml-35 {
    margin-left: calc(var(--offset-base-size)  * 35);
}
.ml-40 {
    margin-left: calc(var(--offset-base-size)  * 40);
}

.mr-1 {
    margin-right: var(--offset-base-size);
}
.mr-2 {
    margin-right: calc(var(--offset-base-size)  * 2);
}
.mr-3 {
    margin-right: calc(var(--offset-base-size)  * 3);
}
.mr-4 {
    margin-right: calc(var(--offset-base-size)  * 4);
}
.mr-5 {
    margin-right: calc(var(--offset-base-size)  * 5);
}
.mr-6 {
    margin-right: calc(var(--offset-base-size)  * 6);
}
.mr-7 {
    margin-right: calc(var(--offset-base-size)  * 7);
}
.mr-8 {
    margin-right: calc(var(--offset-base-size)  * 8);
}
.mr-9 {
    margin-right: calc(var(--offset-base-size)  * 9);
}
.mr-10 {
    margin-right: calc(var(--offset-base-size)  * 10);
}
.mr-12 {
    margin-right: calc(var(--offset-base-size)  * 12);
}
.mr-15 {
    margin-right: calc(var(--offset-base-size)  * 15);
}
.mr-16 {
    margin-right: calc(var(--offset-base-size)  * 16);
}
.mr-20 {
    margin-right: calc(var(--offset-base-size)  * 20);
}
.mr-25 {
    margin-right: calc(var(--offset-base-size)  * 25);
}
.mr-30 {
    margin-right: calc(var(--offset-base-size)  * 30);
}
.mr-35 {
    margin-right: calc(var(--offset-base-size)  * 35);
}
.mr-40 {
    margin-right: calc(var(--offset-base-size)  * 40);
}

.mt-1 {
    margin-top: var(--offset-base-size);
}
.mt-2 {
    margin-top: calc(var(--offset-base-size)  * 2);
}
.mt-3 {
    margin-top: calc(var(--offset-base-size)  * 3);
}
.mt-4 {
    margin-top: calc(var(--offset-base-size)  * 4);
}
.mt-5 {
    margin-top: calc(var(--offset-base-size)  * 5);
}
.mt-6 {
    margin-top: calc(var(--offset-base-size)  * 6);
}
.mt-7 {
    margin-top: calc(var(--offset-base-size)  * 7);
}
.mt-8 {
    margin-top: calc(var(--offset-base-size)  * 8);
}
.mt-9 {
    margin-top: calc(var(--offset-base-size)  * 9);
}
.mt-10 {
    margin-top: calc(var(--offset-base-size)  * 10);
}
.mt-12 {
    margin-top: calc(var(--offset-base-size)  * 12);
}
.mt-15 {
    margin-top: calc(var(--offset-base-size)  * 15);
}
.mt-16 {
    margin-top: calc(var(--offset-base-size)  * 16);
}
.mt-20 {
    margin-top: calc(var(--offset-base-size)  * 20);
}
.mt-25 {
    margin-top: calc(var(--offset-base-size)  * 25);
}
.mt-30 {
    margin-top: calc(var(--offset-base-size)  * 30);
}
.mt-35 {
    margin-top: calc(var(--offset-base-size)  * 35);
}
.mt-40 {
    margin-top: calc(var(--offset-base-size)  * 40);
}

.mb-1 {
    margin-bottom: var(--offset-base-size);
}
.mb-2 {
    margin-bottom: calc(var(--offset-base-size)  * 2);
}
.mb-3 {
    margin-bottom: calc(var(--offset-base-size)  * 3);
}
.mb-4 {
    margin-bottom: calc(var(--offset-base-size)  * 4);
}
.mb-5 {
    margin-bottom: calc(var(--offset-base-size)  * 5);
}
.mb-6 {
    margin-bottom: calc(var(--offset-base-size)  * 6);
}
.mb-7 {
    margin-bottom: calc(var(--offset-base-size)  * 7);
}
.mb-8 {
    margin-bottom: calc(var(--offset-base-size)  * 8);
}
.mb-9 {
    margin-bottom: calc(var(--offset-base-size)  * 9);
}
.mb-10 {
    margin-bottom: calc(var(--offset-base-size)  * 10);
}
.mb-12 {
    margin-bottom: calc(var(--offset-base-size)  * 12);
}
.mb-15 {
    margin-bottom: calc(var(--offset-base-size)  * 15);
}
.mb-16 {
    margin-bottom: calc(var(--offset-base-size)  * 16);
}
.mb-20 {
    margin-bottom: calc(var(--offset-base-size)  * 20);
}
.mb-25 {
    margin-bottom: calc(var(--offset-base-size)  * 25);
}
.mb-30 {
    margin-bottom: calc(var(--offset-base-size)  * 30);
}
.mb-35 {
    margin-bottom: calc(var(--offset-base-size)  * 35);
}
.mb-40 {
    margin-bottom: calc(var(--offset-base-size)  * 40);
}
