.app {
  text-align: center;
}

.content {
  margin: 0 auto;
  padding: 0 0px;
}

@media screen and (max-width: 540px) {
  .content {
    padding: 0 14px;
  }
}

.dark-map {
  filter: brightness(0.6),
  
}

.mapposition {
  position: absolute;
  
}