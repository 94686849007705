@import url('https://fonts.googleapis.com/css?family=Lato:300&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  color: #f4f4f9;
  display: flex;
  justify-content: center;
}

.header {
  width: 100vh;
  max-width: 90%; /* ширина заголовка 90% экрана */
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.2); /* эффект 3D */
  border-radius: 12px; /* закругленные углы */
  margin: 1rem auto; /* добавим отступ сверху и снизу */
  transform: translateY(-5px); /* небольшой сдвиг вверх для эффекта 3D */
}

.user {
  font-size: 1.2rem;
  color: #f4f4f9;
}

.nav {
  display: flex;
  gap: 1rem;
}

.link, .button {
  color: #f4f4f9;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 2px solid #f4f4f9;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
}

.link:hover, .button:hover {
  background-color: #f4f4f9;
  color: #2a5298;
  cursor: pointer;
  transform: translateY(-3px); /* легкий подъем при наведении */
}

.button {
  background: transparent;
  font-weight: 700;
}
